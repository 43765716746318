<template>
  <div v-if="readonly">
    <slot name="value" v-bind="{ innerValue }"><label v-if="!hideLabel" class="col-form-label">{{labelWithSuffix}}</label><p v-if="innerValue" class="font-bold">{{innerValue}}</p></slot>
  </div>
  <div v-else>
    <ValidationProvider :vid="vid" :name="name" :rules="rules" ref="valprov">
      <b-form-group slot-scope="{ invalid, valid, validated, errors }" :label="labelWithSuffix" :label-sr-only="hideLabel" :label-for="$attrs.id" :description="description">
        <b-form-input v-model="innerValue" :id="$attrs.id" :name="name" :state="validated ? valid : null" :type="type" @input="onInput(invalid)">
        </b-form-input>
        <b-form-invalid-feedback><span v-for="(error,idx) in errors" :key="idx">{{error}}</span></b-form-invalid-feedback>
      </b-form-group>
    </ValidationProvider>
    <small v-if="showInfoAboutConfirmation" :id="'help_' + $attrs.id" class="form-text text-muted">Please note: changing the email requires the user to click on the confirmation link received at the new email address. The current email will not change until the user confirmed the new email. See the Unconfirmed Email field. Use the Resend confirmation email button to resend the confirmation email to the unconfirmed email. Changing the current email updates the unconfirmad email and resend the confirmation email.</small>
  </div>
</template>
<script>
import TextField from '../inputs/text.vue'

export default {
  extends: TextField,
  props: {
    type: {
      type: String,
      default: 'email'
    },
    label: {
      type: String,
      default: 'E-mail'
    },
    name: {
      type: String,
      default: 'email'
    },
    rules: {
      type: String,
      default: 'required|email'
    },
    showInfoAboutConfirmation: {
      type: Boolean,
      default: false
    },
  }
}
</script>
